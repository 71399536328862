var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "d-flex flex-row gap-1 ".concat(_vm.center ? 'align-items-center justify-content-center' : _vm.between ? 'align-items-center justify-content-between' : _vm.end ? 'align-items-center justify-content-end' : ''),
    style: "".concat(_vm.gap ? "gap: ".concat(_vm.gap, "px") : '')
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }