<template lang="">
  <div
    :class="`d-flex flex-column gap-1 ${center ? 'align-items-center justify-content-center' : end ? 'align-items-end justify-content-center' : ''}`"
    :style="`${gap ? `gap: ${gap}px` : ''}`"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    gap: {
      type: [String, Number, null],
      default: null,
    },
    center: {
      type: Boolean,
      default: false,
    },
    end: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
