/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import service, { useJwtPublic } from './service'

export default {
  fetchAgencies(_payload = {}) {
    const { path = '' } = _payload
    return service.get(`/agencies${path}`, _payload)
  },

  fetchReglist(_payload = {}) {
    const { path = '' } = _payload
    return service.get(`/register_agencies${path}`, _payload)
  },

  getAgenciesById(id, payload = {}) {
    return service.get(`/agencies/${id}`, payload)
  },

  addAgencies(payload = {}) {
    return service.post('/agencies', payload)
  },

  validateEmail(payload = {}) {
    return service.post('/agencies/validate-email', payload)
  },
  validateAgencyCode(payload = {}) {
    return service.post('/agencies/validate-agency-code', payload)
  },

  validatePhonenumber(payload = {}) {
    return service.post('/agencies/validate-phone-number', payload)
  },

  updateAgencies(id = '', payload = {}) {
    return service.patch(`/agencies/${id}`, payload)
  },

  bulkDeleteAgencies(ids = {}) {
    return service.post('/agencies/bulk-delete', ids)
  },

  bulkDeactiveAgencies(ids = {}) {
    return service.post('/agencies/bulk-deactive', ids)
  },

  updateActive(id = '', payload = {}) {
    return service.patch(`/agencies/${id}/active`, payload)
  },

  updateDeactive(id = '', payload = {}) {
    return service.patch(`/agencies/${id}/deactive`, payload)
  },

  exportAgencies(payload = {}, fileName) {
    return service.excel('/agencies/export', payload, fileName)
  },

  exportAgenciesTemplate(fileName) {
    return service.excel('/agencies/export-template', {}, fileName)
  },

  bulkInsert(payload = {}) {
    return service.post('/agencies/bulk-insert', payload)
  },

  getFeeConfigs(id) {
    return service.get(`/agencies/${id}/configs`)
  },

  createFeeConfig(id, payload) {
    return service.post(`/agencies/${id}/configs`, payload)
  },

  updateAgenciesFee(id, payload) {
    return service.put(`/agencies/${id}/update-agency-fee`, payload)
  },

  assignManagers(id, payload) {
    return service.put(`/agencies/${id}/managers`, payload)
  },

  checkCredit(id, payload) {
    return service.get(`/agencies/${id}/check-credit`, payload)
  },

  updateCredit(id, payload) {
    return service.patch(`/agencies/${id}/update-credit`, payload)
  },

  updateIssueLimit(id, payload) {
    return service.put(`/agencies/${id}/update-issue-limit`, payload)
  },

  updateBookingLimit(id, payload) {
    return service.put(`/agencies/${id}/update-booking-limit`, payload)
  },

  updateRefundLimit(id, payload) {
    return service.put(`/agencies/${id}/update-refund-limit`, payload)
  },

  updateVoidLimit(id, payload) {
    return service.put(`/agencies/${id}/update-void-limit`, payload)
  },

  updateBookerId(id, payload) {
    return service.put('/agencies/booker-id', payload)
  },

  updateHideFeeConfig(id) {
    return service.patch(`/agencies/${id}/hide-fee`)
  },

  SetLowFareTracking(id) {
    return service.patch(`/agencies/${id}/enable-low-fare-tracking`)
  },

  updateAgenciesFeeConfig(id) {
    return service.patch(`/agencies/${id}/apply-agency-fee`)
  },

  updateSendMailAirline(id, payload) {
    return service.patch(`/agencies/${id}/send-mail-airline`, payload)
  },
  updateSendPhoneAirline(id, payload) {
    return service.patch(`/agencies/${id}/send-phone-airline`, payload)
  },

  getChild(id, params = {}) {
    return service.get(`/agencies/${id}/child`, params)
  },

  getBankAccountsList(agencyId) {
    return service.get(`/bank-accounts/agency/${agencyId}`)
  },

  addBankAccounts(agencyId, payload) {
    return service.post(`/agencies/${agencyId}/bank-accounts`, payload)
  },

  updateBankAccounts(agencyId, payload) {
    return service.put(`/agencies/${agencyId}/bank-accounts`, payload)
  },

  changeNote(id, payload) {
    return service.put(`/agencies/${id}/note`, payload)
  },

  changeSocialNetwork(id, payload) {
    return service.put(`/agencies/${id}/social-accounts`, payload)
  },

  resetBalance(id, payload) {
    return service.post(`/agencies/${id}/reset-balance`, payload)
  },

  // Cho phép book đoàn
  enableBookingGroup(id) {
    return service.patch(`/agencies/${id}/enable-booking-group`)
  },

  // cho phép nhân viên nhận mail hãng
  enableSendEmployeeMailAirline(id) {
    return service.patch(`/agencies/${id}/enable-send-employee-mail-airline`)
  },

  updateEnableQuickName(id) {
    return service.patch(`/agencies/${id}/enable-quick-name`)
  },

  updateEnableRebook(id) {
    return service.patch(`/agencies/${id}/enable-rebook`)
  },

  updateEnableRetail(id) {
    return service.patch(`/agencies/${id}/enable-retail`)
  },

  updateEnableInvoice(id) {
    return service.patch(`/agencies/${id}/enable-invoice`)
  },

  updateEnableTour(id) {
    return service.patch(`/agencies/${id}/enable-tour`)
  },

  updateEnableTrain(id) {
    return service.patch(`/agencies/${id}/enable-train`)
  },

  updateLimitTimeSearch(id, payload) {
    return service.put(`/agencies/${id}/update-limit-time-search`, payload)
  },

  updateLimitPaxBooking(id, payload) {
    return service.put(`/agencies/${id}/update-limit-pax-booking`, payload)
  },

  getBanks() {
    return service.get(`/bank-accounts/agency/${agencyId}`)
  },

  addBankAccount(payload) {
    return service.post('/bank-accounts', payload)
  },

  // company
  createCompany(agencyId, payload) {
    return service.post(`/agencies/${agencyId}/company`, payload)
  },
  updateCompany(agencyId, payload) {
    return service.put(`/agencies/${agencyId}/company`, payload)
  },

  toggleActiveCompany(companyId, payload) {
    return service.put(`/company/${companyId}/active`, payload)
  },

  // web-config
  createWebConfig(agencyId, payload) {
    return service.post(`/agencies/${agencyId}/agency-config`, payload)
  },
  updateWebConfig(agencyId, payload) {
    return service.patch(`/agencies/${agencyId}/agency-config`, payload)
  },
  // send email test

  sendEmailTest(payload) {
    return service.post('/agencies/test-send-email', payload)
  },

  // send Noti all
  sendNotiAll(payload) {
    return service.post('/agencies/send-noti-all', payload)
  },
}
