import axios from 'axios'
// import Vue from 'vue'

import useJwt from '@core/auth/jwt/useJwt'

import { instanceUser } from './useJwt'

export const instanceCms = axios.create({
  // baseURL: 'http://localhost:3001/api/',
  // baseURL: 'http://192.168.1.29:3001/api/',
  baseURL: process.env.VUE_APP_API_CMS,
})

const { jwt: jwtCms } = useJwt(instanceCms, {
  adUsername: true, // cms gửi lên thêm `ad-username`
  // urlEndpointIgnoreTokenList: ['/fe_configs'], // fe_config ko can gui token
}, instanceUser)

export default jwtCms
